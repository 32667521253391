import styled, { css, createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'
import {
  ButtonProps,
  Colors,
  LAYOUT_TEMPLATE,
  TextProps
} from '../services/api/types'
import { colors, fontSize, size } from './theme'
import { SVGIcon } from '../components/base/icon/svgIcon'
import { PAGE_TEMPLATE } from '../services/api/constants'

export const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  .button-link {
    ${tw`text-white font-bold uppercase text-2xl bg-blue__deep hover:bg-blue__dark2 transition duration-300 px-4 py-3 italic leading-tight`}
  }

  .hero-form {
    ${tw`mb-20`}
  }

  .mapboxgl-popup > .mapboxgl-popup-content {
    ${tw`bg-grey__light flex flex-row items-stretch p-0`}
  }

  .pb-16 {
    ${tw`pb-16`}
  }
`

export const getBgColor = (color: string) => {
  const lowerColor: string = color.toLowerCase()

  switch (lowerColor) {
    case 'black':
      return tw`bg-black`
    case 'white':
      return tw`bg-white`
    case 'blue':
      return tw`bg-blue`
    case 'blue-dark':
      return tw`bg-blue__dark`
    case 'blue-grey':
      return tw`bg-blue__grey`
    case 'blue-dodger':
      return tw`bg-blue__dodger`
    case 'blue-deep':
      return tw`bg-blue__deep`
    case 'black-deep':
      return tw`bg-black__deep`
    case 'orange':
      return tw`bg-orange`
    case 'orange-dark':
      return tw`bg-orange__dark`
    case 'green':
      return tw`bg-green`
    case 'grey':
      return tw`bg-grey`
    case 'grey-light':
      return tw`bg-grey__light`
    case 'grey-medium':
      return tw`bg-grey__medium`
    case 'grey-dark':
      return tw`bg-grey__dark`
    case 'silver':
      return tw`bg-silver`
    case 'grey-sand':
      return tw`bg-grey__sand`
    case 'plus_orage':
      return tw`bg-plus_orage`
    case 'plus_blue':
      return tw`bg-plus_blue`
    case 'plus_dark_grey':
      return tw`bg-plus_dark_grey`
    case 'plus_light_grey':
      return tw`bg-plus_light_grey`
    case 'plus_undercoat_grey':
      return tw`bg-plus_undercoat_grey`
    case 'plus_white':
      return tw`bg-plus_white`
    case 'plus_header_grey':
      return tw`bg-plus_header_grey`
    default:
      return null
  }
}

export const Section = styled.section<{
  bgImgURL?: string
  bgColor?: Colors
  position?: 'absolute' | 'relative'
  spacing?: 4 | 8 | 12 | 16 | 24 | 32 | 48
}>`

  ${tw`w-full`}

  ${({ position }) => {
    switch (position) {
      case 'absolute':
        return tw`absolute`
      case 'relative':
        return tw`relative`
      default:
        return null
    }
  }}
  
  ${({ bgColor }) => bgColor && getBgColor(bgColor)}

  ${({ spacing }) => {
    switch (spacing) {
      case 4:
        return tw`py-4`
      case 8:
        return tw`py-8`
      case 12:
        return tw`py-12`
      case 16:
        return tw`py-16`
      case 24:
        return tw`py-24`
      case 32:
        return tw`py-32`
      case 48:
        return tw`py-48`
      default:
        return null
    }
  }}

  ${({ bgImgURL }) =>
    bgImgURL && `background:url('${bgImgURL}') center/cover no-repeat;`}
`

const AppStyles = styled.div<{ template: LAYOUT_TEMPLATE }>`
  ${({ template }) => {
    if (template == PAGE_TEMPLATE.YOU_FITNESS || template == "2021") {
      return tw`font-body w-full block bg-black__deep`
    } else {
      return tw`font-body w-full block`
    }
  }}}
`

export const FlexContainer = styled.div<{
  position?: 'absolute' | 'relative'
  flow?: 'row' | 'col'
  verticalAlign?: 'center' | 'start' | 'end' | 'stretch'
  horizontalAlign?: 'center' | 'start' | 'end' | 'between'
  textAlign?: 'left' | 'center' | 'right'
  contentSize?: 'sm' | 'md' | 'lg' | 'xl'
}>`
  ${tw`mx-auto flex`}
  ${({ contentSize }) => {
    switch (contentSize) {
      case 'sm':
        return tw`w-full sm:max-w-screen-sm`
      case 'md':
        return tw`w-full sm:max-w-screen-md`
      case 'lg':
        return tw`w-full sm:max-w-screen-lg`
      case 'xl':
        return tw`w-full sm:max-w-screen-xl`
      default:
        return tw`container`
    }
  }}
  ${({ position }) => {
    switch (position) {
      case 'absolute':
        return tw`absolute`
      case 'relative':
        return tw`relative`
      default:
        return tw``
    }
  }}

  ${({ flow }) => {
    return flow === 'row' ? tw`flex-row` : tw`flex-col`
  }}

  ${({ verticalAlign }) => {
    switch (verticalAlign) {
      case 'center':
        return tw`items-center`
      case 'end':
        return tw`items-end`
      case 'start':
        return tw`items-start`
      case 'stretch':
        return tw`items-stretch`
    }
  }}

  ${({ horizontalAlign }) => {
    switch (horizontalAlign) {
      case 'center':
        return tw`justify-center content-center`
      case 'end':
        return tw`justify-end`
      case 'start':
        return tw`justify-start`
      case 'between':
        return tw`justify-between`
    }
  }}

  ${({ textAlign }) => {
    switch (textAlign) {
      case 'center':
        return tw`text-center`
      case 'left':
        return tw`text-left`
      case 'right':
        return tw`text-right`
    }
  }}
`

export const Container = styled.div<{
  contentSize?: 'sm' | 'md' | 'lg' | 'xl'
  position?: 'absolute' | 'relative'
}>`
  ${tw`w-full mx-auto px-2 xl:px-0`}
  ${({ contentSize }) => {
    switch (contentSize) {
      case 'sm':
        return tw`w-full sm:max-w-screen-sm`
      case 'md':
        return tw`w-full sm:max-w-screen-md`
      case 'lg':
        return tw`w-full sm:max-w-screen-lg`
      case 'xl':
        return tw`w-full sm:max-w-screen-xl`
      default:
        return tw`w-full container`
    }
  }}

  ${({ position }) => {
    switch (position) {
      case 'absolute':
        return tw`absolute`
      case 'relative':
        return tw`relative`
      default:
        return null
    }
  }}

  img, svg, video, canvas, audio, iframe, embed, object {
    display: inline-block;
  }
`

export const CenteredContainer = styled(Container)`
  ${tw`flex justify-center content-center text-center flex-col items-center py-8`}
`
export const FullWidthWrapper = styled.div`
  ${tw`w-full`}

  @media (max-width: 767px) {
    min-height: auto !important;
  }
`

export const FullWidthImg = styled.img`
  ${tw`w-full`}
`

export const DefaultImg = styled.img<{ objectFit?: string }>`
  width: 100%;
  height: 100%;
  margin-right:10px;
  ${props =>
    props.objectFit == 'cover' ? 'object-fit: cover;' : 'object-fit: contain;'}
`

export const GymContainer = styled.div`
  ${tw`w-full mx-auto`}
  max-width: ${size(1050)};
  
  @media (max-width: 1279px) {
    max-width: unset;
    padding: 0 40px;
  }
  
  @media (max-width: 480px) {
    max-width: unset;
    padding: 0;
  }
`

export const WhiteButton = styled.a`
  ${tw`bg-white text-sm py-2.5 px-8 text-blue__deep transition-colors duration-300 inline-flex items-center capitalize text-center`}
  line-height: 1.5;

  figure {
    width: ${size(14)};
    height: ${size(14)};
    ${tw`text-sm mr-2.5 mb-0`}
  }

  &:hover {
    background-color: #cccccc;
  }
`

export const BorderButton = styled.a`
  ${tw`bg-transparent font-medium border py-2.5 px-8 border-white hover:bg-blue__deep hover:border-blue__deep hover:text-white text-sm text-white transition-colors duration-300 inline-block capitalize text-center`}
  line-height: 1.5;

  figure {
    width: ${size(14)};
    height: ${size(14)};
    ${tw`text-sm mr-2.5 mb-0`}
  }
`

export const BlueButton = styled.button`
  ${tw`bg-blue__deep py-2.5 font-medium px-8 text-sm text-white transition-colors duration-300 inline-flex items-center`}
  line-height: 1.5;

  figure {
    width: ${size(14)};
    height: ${size(14)};
    ${tw`text-sm mr-2.5 mb-0`}
  }

  &:hover {
    ${tw`bg-blue__dark2`}
  }
`

export const BlueButtonLink = styled.a`
  ${tw`bg-blue__deep text-white font-medium text-sm py-2.5 px-8 transition-colors duration-300 inline-flex items-center`}
  line-height: 1.5;

  figure {
    ${tw`text-sm mr-2.5 mb-0`}
    width: ${size(14)};
    height: ${size(14)};
  }

  &:hover {
    ${tw`bg-blue__dark2`}
  }
`

export const BlueButtonCustom = styled.button`
  ${tw`bg-blue__deep text-white transition-colors duration-300 flex items-center`}
  line-height: 2.1;
  padding: 0.35rem 2rem;
  font-weight: 500;
  ${fontSize(14)}

  figure {
    margin-bottom: 0;
    margin-right: ${size(10)};
    width: ${size(14)};
    height: ${size(14)};
  }

  &:hover {
    ${tw`bg-blue__dark2`}
  }
`

export const Space = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}em` : '3rem')};
`

export const Link = styled.a`
  ${tw`hover:text-orange hover:underline cursor-pointer`}
`

export const Card = styled.div`
  ${tw`relative flex flex-1 flex-col text-center font-display w-full p-4`}
`

export const CardHeader = styled.h2<{ separator?: boolean }>`
  ${tw`relative text-blue__dark text-center text-4xl px-6 pb-4 uppercase font-extrabold`}

  margin-bottom: 20px;

  &::after {
    ${props => props.separator && tw`absolute bottom-0 h-1 bg-orange`}
    content: " ";
    min-width: 185px;
    left: 50%;
    transform: translateX(-50%) rotate(-4deg);
  }
`
export const CardHeaderH1 = styled.h1<{ separator?: boolean }>`
  ${tw`relative text-blue__dark text-center text-4xl px-6 pb-4 uppercase font-extrabold`}

  margin-bottom: 20px;

  &::after {
    ${props => props.separator && tw`absolute bottom-0 h-1 bg-orange`}
    content: " ";
    min-width: 185px;
    left: 50%;
    transform: translateX(-50%) rotate(-4deg);
  }
`

export const CardBody = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`flex flex-col items-center`}

  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`bg-black__deep`}
    `}
`

export const CardFooter = styled.div`
  ${tw`flex justify-center`}

  & > button {
    ${tw`mx-1`}
    min-width: 150px;
  }
`

export const Underline = styled.hr<{
  color?: 'blue' | 'orange' | 'blue-dark' | 'blue-deep'
  skew?: boolean
}>`
  ${tw`mt-4 w-32 border-t-4 border-solid border-orange transform`}
  ${({ skew }) =>
    skew !== false &&
    css`
      ${tw`-skew-y-3`}
    `}
  ${({ color }) => {
    switch (color) {
      case 'blue':
        return tw`border-blue`
      case 'blue-dark':
        return tw`border-blue__dark`
      case 'orange':
        return tw`border-orange`
      case 'blue-deep':
        return tw`border-blue__deep`
    }
  }}
`

export const UnderlineCustom = styled.div<{
  color?: 'blue' | 'orange' | 'blue-dark' | 'blue-deep'
}>`
  width: 70px;
  height: 4px;

  ${({ color }) => {
    switch (color) {
      case 'blue':
        return tw`bg-blue`
      case 'blue-dark':
        return tw`bg-blue__dark`
      case 'orange':
        return tw`bg-orange`
      case 'blue-deep':
        return tw`bg-blue__deep`
    }
  }}
`

export const Text = styled.div<TextProps>`
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return tw`text-sm`
      case 'md':
        return tw`text-base`
      case 'lg':
        return tw`text-lg`
      case 'xl':
        return tw`text-xl`
      case '2xl':
        return tw`text-2xl`
      case '4xl':
        return tw`text-4xl`
      case '5xl':
        return tw`text-5xl`
      case '6xl':
        return tw`text-4xl lg:text-6xl`
      default:
        return tw`text-base`
    }
  }}
   ${({ color }) => {
     switch (color) {
       case 'black':
         return tw`text-black`
       case 'white':
         return tw`text-white`
       case 'white-deeper':
         return tw`text-white__deeper`
       case 'blue':
         return tw`text-blue`
       case 'blue-dark':
         return tw`text-blue__dark`
       case 'blue-deep':
         return tw`text-blue__deep`
       case 'blue-grey':
         return tw`text-blue__grey`
       case 'blue-dodger':
         return tw`text-blue__dodger`
       case 'orange':
         return tw`text-orange`
       case 'orange-dark':
         return tw`text-orange__dark`
       case 'green':
         return tw`text-green`
       case 'grey':
         return tw`text-grey`
       case 'grey-light':
         return tw`text-grey__light`
       case 'grey-medium':
         return tw`text-grey__medium`
       case 'grey-dark':
         return tw`text-grey__dark`
       case 'grey-sand':
         return tw`text-grey__sand`
       case 'silver':
         return tw`text-silver`
       default:
         return null
     }
   }}
  ${({ fontWeight }) => {
    switch (fontWeight) {
      case 'thin':
        return tw`font-thin`
      case 'bold':
        return tw`font-bold`
      case 'semibold':
        return tw`font-semibold`
      case 'extrabold':
        return tw`font-extrabold`
      default:
        return null
    }
  }}
  ${({ textCase }) => {
    switch (textCase) {
      case 'upper':
        return tw`uppercase`
      case 'lower':
        return tw`lowercase`
      default:
        return null
    }
  }}
  ${({ lineHeight }) => {
    switch (lineHeight) {
      case 'none':
        return tw`leading-none`
      case 'tight':
        return tw`leading-tight`
      case 'snug':
        return tw`leading-snug`
      case 'normal':
        return tw`leading-normal`
      case 'relaxed':
        return tw`leading-relaxed`
      case 'loose':
        return tw`leading-loose`
      default:
        return null
    }
  }}

  ${({ textDecoration }) => {
    switch (textDecoration) {
      case 'underline':
        return tw`underline`
      case 'line-through':
        return tw`line-through`
      default:
        return null
    }
  }}

  ${({ fontStyle }) => {
    switch (fontStyle) {
      case 'italic':
        return tw`italic`
      default:
        return null
    }
  }}

`
export const FitnessTextWrap = styled.div`
h1, h3, h2, a {
  color: #2533D5;
}
`

export const TextParaCustom = styled.a`
  ${tw`mb-0 text-grey__dark`}
  display: block;

  &:hover {
    color: #ee6c16;
  }

  span {
    ${tw`uppercase`}
    word-break: break-all;
  }
`

export const TextPara = styled.a<TextProps>`
  ${tw`mb-0`}
  display: block;
  
    ${({ isHover }) => {
      if (isHover) {
        return `
        &:hover {
            color: #ee6c16;
        }
        `
      }
    }}

  span {
    ${tw`uppercase`}
    word-break: break-all;
    ${({ isUnderline }) => {
      if (isUnderline) {
        return ` text-decoration: underline;`
      }
    }}
    
    @media (max-width: 480px) {
        text-decoration: underline;
    }
  }

  ${({ size }) => {
    switch (size) {
      case 'sm':
        return tw`text-sm`
      case 'md':
        return tw`text-base`
      case 'lg':
        return tw`text-lg`
      case 'xl':
        return tw`text-xl`
      case '2xl':
        return tw`text-2xl`
      case '4xl':
        return tw`text-4xl`
      case '5xl':
        return tw`text-5xl`
      case '6xl':
        return tw`text-4xl lg:text-6xl`
      default:
        return tw`text-base`
    }
  }}
   ${({ color }) => {
     switch (color) {
       case 'black':
         return tw`text-black`
       case 'white':
         return tw`text-white`
       case 'blue':
         return tw`text-blue`
       case 'blue-dark':
         return tw`text-blue__dark`
       case 'blue-grey':
         return tw`text-blue__grey`
       case 'blue-dodger':
         return tw`text-blue__dodger`
       case 'orange':
         return tw`text-orange`
       case 'orange-dark':
         return tw`text-orange__dark`
       case 'green':
         return tw`text-green`
       case 'grey':
         return tw`text-grey`
       case 'grey-light':
         return tw`text-grey__light`
       case 'grey-medium':
         return tw`text-grey__medium`
       case 'grey-dark':
         return tw`text-grey__dark`
       case 'silver':
         return tw`text-silver`
       default:
         return null
     }
   }}
  ${({ fontWeight }) => {
    switch (fontWeight) {
      case 'thin':
        return tw`font-thin`
      case 'bold':
        return tw`font-bold`
      case 'semibold':
        return tw`font-semibold`
      case 'extrabold':
        return tw`font-extrabold`
      default:
        return null
    }
  }}
  ${({ textCase }) => {
    switch (textCase) {
      case 'upper':
        return tw`uppercase`
      case 'lower':
        return tw`lowercase`
      default:
        return null
    }
  }}
  ${({ lineHeight }) => {
    switch (lineHeight) {
      case 'none':
        return tw`leading-none`
      case 'tight':
        return tw`leading-tight`
      case 'snug':
        return tw`leading-snug`
      case 'normal':
        return tw`leading-normal`
      case 'relaxed':
        return tw`leading-relaxed`
      case 'loose':
        return tw`leading-loose`
      default:
        return null
    }
  }}

  ${({ textDecoration }) => {
    switch (textDecoration) {
      case 'underline':
        return tw`underline`
      case 'line-through':
        return tw`line-through`
      default:
        return null
    }
  }}

  ${({ fontStyle }) => {
    switch (fontStyle) {
      case 'italic':
        return tw`italic`
      default:
        return null
    }
  }}

`

export const TextH1 = styled.h1<TextProps>`
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return tw`text-sm`
      case 'md':
        return tw`text-base`
      case 'lg':
        return tw`text-lg`
      case 'xl':
        return tw`text-xl`
      case '2xl':
        return tw`text-2xl`
      case '4xl':
        return tw`text-4xl`
      case '5xl':
        return tw`text-5xl`
      case '6xl':
        return tw`text-4xl lg:text-6xl`
      default:
        return tw`text-base`
    }
  }}
   ${({ color }) => {
     switch (color) {
       case 'black':
         return tw`text-black`
       case 'white':
         return tw`text-white`
       case 'blue':
         return tw`text-blue`
       case 'blue-dark':
         return tw`text-blue__dark`
       case 'blue-grey':
         return tw`text-blue__grey`
       case 'blue-dodger':
         return tw`text-blue__dodger`
       case 'orange':
         return tw`text-orange`
       case 'orange-dark':
         return tw`text-orange__dark`
       case 'green':
         return tw`text-green`
       case 'grey':
         return tw`text-grey`
       case 'grey-light':
         return tw`text-grey__light`
       case 'grey-medium':
         return tw`text-grey__medium`
       case 'grey-dark':
         return tw`text-grey__dark`
       case 'silver':
         return tw`text-silver`
       default:
         return null
     }
   }}
  ${({ fontWeight }) => {
    switch (fontWeight) {
      case 'thin':
        return tw`font-thin`
      case 'bold':
        return tw`font-bold`
      case 'semibold':
        return tw`font-semibold`
      case 'extrabold':
        return tw`font-extrabold`
      default:
        return null
    }
  }}
  ${({ textCase }) => {
    switch (textCase) {
      case 'upper':
        return tw`uppercase`
      case 'lower':
        return tw`lowercase`
      default:
        return null
    }
  }}
  ${({ lineHeight }) => {
    switch (lineHeight) {
      case 'none':
        return tw`leading-none`
      case 'tight':
        return tw`leading-tight`
      case 'snug':
        return tw`leading-snug`
      case 'normal':
        return tw`leading-normal`
      case 'relaxed':
        return tw`leading-relaxed`
      case 'loose':
        return tw`leading-loose`
      default:
        return null
    }
  }}

  ${({ textDecoration }) => {
    switch (textDecoration) {
      case 'underline':
        return tw`underline`
      case 'line-through':
        return tw`line-through`
      default:
        return null
    }
  }}

  ${({ fontStyle }) => {
    switch (fontStyle) {
      case 'italic':
        return tw`italic`
      default:
        return null
    }
  }}

`

export const TextH2 = styled.h2<TextProps>`
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return tw`text-sm`
      case 'md':
        return tw`text-base`
      case 'lg':
        return tw`text-lg`
      case 'xl':
        return tw`text-xl`
      case '2xl':
        return tw`text-2xl`
      case '4xl':
        return tw`text-4xl`
      case '5xl':
        return tw`text-5xl`
      case '6xl':
        return tw`text-4xl lg:text-6xl`
      default:
        return tw`text-base`
    }
  }}
   ${({ color }) => {
     switch (color) {
       case 'black':
         return tw`text-black`
       case 'white':
         return tw`text-white`
       case 'blue':
         return tw`text-blue`
       case 'blue-dark':
         return tw`text-blue__dark`
       case 'blue-grey':
         return tw`text-blue__grey`
       case 'blue-dodger':
         return tw`text-blue__dodger`
       case 'orange':
         return tw`text-orange`
       case 'orange-dark':
         return tw`text-orange__dark`
       case 'green':
         return tw`text-green`
       case 'grey':
         return tw`text-grey`
       case 'grey-light':
         return tw`text-grey__light`
       case 'grey-medium':
         return tw`text-grey__medium`
       case 'grey-dark':
         return tw`text-grey__dark`
       case 'silver':
         return tw`text-silver`
       default:
         return null
     }
   }}
  ${({ fontWeight }) => {
    switch (fontWeight) {
      case 'thin':
        return tw`font-thin`
      case 'bold':
        return tw`font-bold`
      case 'semibold':
        return tw`font-semibold`
      case 'extrabold':
        return tw`font-extrabold`
      default:
        return null
    }
  }}
  ${({ textCase }) => {
    switch (textCase) {
      case 'upper':
        return tw`uppercase`
      case 'lower':
        return tw`lowercase`
      default:
        return null
    }
  }}
  ${({ lineHeight }) => {
    switch (lineHeight) {
      case 'none':
        return tw`leading-none`
      case 'tight':
        return tw`leading-tight`
      case 'snug':
        return tw`leading-snug`
      case 'normal':
        return tw`leading-normal`
      case 'relaxed':
        return tw`leading-relaxed`
      case 'loose':
        return tw`leading-loose`
      default:
        return null
    }
  }}

  ${({ textDecoration }) => {
    switch (textDecoration) {
      case 'underline':
        return tw`underline`
      case 'line-through':
        return tw`line-through`
      default:
        return null
    }
  }}

  ${({ fontStyle }) => {
    switch (fontStyle) {
      case 'italic':
        return tw`italic`
      default:
        return null
    }
  }}

`

export const TextH3 = styled.h3<TextProps>`
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return tw`text-sm`
      case 'md':
        return tw`text-base`
      case 'lg':
        return tw`text-lg`
      case 'xl':
        return tw`text-xl`
      case '2xl':
        return tw`text-2xl`
      case '4xl':
        return tw`text-4xl`
      case '5xl':
        return tw`text-5xl`
      case '6xl':
        return tw`text-4xl lg:text-6xl`
      default:
        return tw`text-base`
    }
  }}
   ${({ color }) => {
     switch (color) {
       case 'black':
         return tw`text-black`
       case 'white':
         return tw`text-white`
       case 'blue':
         return tw`text-blue`
       case 'blue-dark':
         return tw`text-blue__dark`
       case 'blue-grey':
         return tw`text-blue__grey`
       case 'blue-dodger':
         return tw`text-blue__dodger`
       case 'orange':
         return tw`text-orange`
       case 'orange-dark':
         return tw`text-orange__dark`
       case 'green':
         return tw`text-green`
       case 'grey':
         return tw`text-grey`
       case 'grey-light':
         return tw`text-grey__light`
       case 'grey-medium':
         return tw`text-grey__medium`
       case 'grey-dark':
         return tw`text-grey__dark`
       case 'silver':
         return tw`text-silver`
       default:
         return null
     }
   }}
  ${({ fontWeight }) => {
    switch (fontWeight) {
      case 'thin':
        return tw`font-thin`
      case 'bold':
        return tw`font-bold`
      case 'semibold':
        return tw`font-semibold`
      case 'extrabold':
        return tw`font-extrabold`
      default:
        return null
    }
  }}
  ${({ textCase }) => {
    switch (textCase) {
      case 'upper':
        return tw`uppercase`
      case 'lower':
        return tw`lowercase`
      default:
        return null
    }
  }}
  ${({ lineHeight }) => {
    switch (lineHeight) {
      case 'none':
        return tw`leading-none`
      case 'tight':
        return tw`leading-tight`
      case 'snug':
        return tw`leading-snug`
      case 'normal':
        return tw`leading-normal`
      case 'relaxed':
        return tw`leading-relaxed`
      case 'loose':
        return tw`leading-loose`
      default:
        return null
    }
  }}

  ${({ textDecoration }) => {
    switch (textDecoration) {
      case 'underline':
        return tw`underline`
      case 'line-through':
        return tw`line-through`
      default:
        return null
    }
  }}

  ${({ fontStyle }) => {
    switch (fontStyle) {
      case 'italic':
        return tw`italic`
      default:
        return null
    }
  }}

`

export const ButtonLink = styled.a<ButtonProps>`
  ${tw`px-4 py-4 lg:py-2 transition-colors duration-300 uppercase italic cursor-pointer inline-block no-underline`}
  // primary
  ${({ color }) => {
    switch (color) {
      case 'blue':
      case 'secondary':
        return tw`bg-blue hover:bg-blue__dark text-white`
      case 'blue-deep':
        return tw`bg-blue__deep hover:bg-blue__dark2 text-white`
      case 'orange':
      case 'primary':
        return tw`bg-orange hover:bg-orange__dark text-white`
      case 'white':
      case 'tertiary':
        return tw`bg-white hover:bg-grey__light text-blue`
      case 'secondary-alt':
        return tw`ml-4 my-2 py-2.5 font-medium text-sm px-8 transition-colors duration-300 cursor-pointer inline-block no-underline font-normal border-none capitalize`
      default:
        return tw`bg-blue hover:bg-blue__dark text-white`
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'sm':
        return tw`text-sm`
      case 'md':
        return tw`text-base`
      case 'lg':
        return tw`text-lg`
      case 'xl':
        return tw`text-xl`
      case '2xl':
        return tw`text-2xl`
      case '4xl':
        return tw`text-4xl`
      case '5xl':
        return tw`text-5xl`
      case '6xl':
        return tw`text-6xl`
      default:
        return null
    }
  }}
  ${({ fontWeight }) => {
    switch (fontWeight) {
      case 'thin':
        return tw`font-thin`
      case 'bold':
        return tw`font-bold`
      case 'semibold':
        return tw`font-semibold`
      case 'extrabold':
        return tw`font-extrabold`
      default:
        return null
    }
  }}
`

export const ContainerGrid = styled(Container)`
  ${tw`w-full flex flex-wrap bg-grey__light`}
`

export const PageTitle = styled.h2<{ isYouFitness?: boolean }>`
  padding-top: 1.5rem !important;

  ${({ isYouFitness }) =>
    isYouFitness &&
    css`
      ${tw`text-white uppercase font-youfitnessHeading text-4xl`}
    `}
`

export const SpinnerWrap = styled.div<{
  isYouFitness?: boolean
  isCenter?: boolean
}>`
  ${tw`flex justify-center items-center w-full mt-16`};

  ${({ isCenter }) =>
    isCenter &&
    css`
      ${tw`absolute mt-0`}
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  ${({ isYouFitness }) =>
    isYouFitness &&
    css`
      ${tw`mt-0`}

      > div {
        ${tw`h-10 w-10 border-4 m-0 border-white`}
        border-top-color: ${colors.blue__deep};
      }
    `}
`

export const Spinner = styled.div`
  ${tw`ease-linear rounded-full border-8 border-t-8 border-gray h-16 w-16 m-5`}
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const OverlayWrapper = styled.div<{
  overlay?: boolean
  color?: 'blue' | 'black' | 'grey'
}>`
  ${tw`w-full h-full`}

  ${({ overlay, color }) => {
    if (overlay) {
      switch (color) {
        case 'blue':
          return tw`bg-blue`
        case 'grey':
          return tw`bg-grey`
        default:
          return tw`bg-black__overlay`
      }
    }
    return null
  }}
`

export const SpinnerOverlayWrap = styled.div`
  ${tw`z-50 absolute h-full w-full bg-blue__overlay justify-center items-start flex content-center top-0 left-0`};
`

export const FormWrapper = styled.form`
  ${tw`w-auto`};
`

export const TemplateFormContainer = styled.div`
  ${tw`w-full xl:w-2/5`}

  & > section:first-of-type {
    padding-top: 0;
  }
`

export const btnIcon = styled.figure``

export const GymBodyCommon = styled.div`
  padding-bottom: ${size(62)}
  padding-top: ${size(62)}
  
  @media (max-width: 768px) {
    padding-bottom: ${size(42)};
    padding-top: ${size(42)}
  }
`

export const GymBodySocials = styled.div`
  padding-bottom: ${size(62)} @media (max-width: 768px) {
    padding-bottom: ${size(62)};
  }
`

export const GymReadyBlock = styled.div``

export const GymCommonWrap = styled.div`
  overflow: hidden;
`

export const GymWrap = styled.div`
  ${tw`bg-black__deep`}
`

export const GymCommonTitle = styled.p`
  ${tw`uppercase text-center text-white font-bold relative`}
  ${fontSize(30)}
  padding: 0 ${size(16)};
  padding-bottom: ${size(25)};

  &::before {
    content: '';
    width: 100%;
    max-width: ${size(70)};
    height: ${size(4)};
    ${tw`bg-blue__deep absolute`}
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
  }
`

export const GymCommonDesc = styled.p`
  ${tw`text-center text-grey__sand`}
  opacity: 0.8;
  ${fontSize(14)}
  padding: 0 ${size(16)};
  padding-bottom: ${size(25)};
`

export const GymCommonDescHuge = styled.p`
  ${tw`uppercase text-center text-grey__sand`}
  opacity: 0.8;
  ${fontSize(16)}
  padding: 0 ${size(16)};
  padding-bottom: ${size(25)};
`

export const GymComp3 = styled.div`
  overflow: hidden;
`

export const GymComp3Group = styled.div`
  ${tw`flex justify-center items-center`}
  margin-top: ${size(40)};
  margin-bottom: ${size(40)};
  
  @media (max-width: 480px) {
    margin-bottom: ${size(30)};
  }
`

export const GymComp3GroupImage = styled.div`
  min-width: ${size(74)};

  &:nth-of-type(2) {
    margin: 0 50px;

    @media (max-width: 480px) {
      margin: 0 ${size(30)};
    }
  }

  @media (max-width: 1100px) {
    width: auto;
    height: ${size(70)};
  }

  @media (max-width: 992px) {
    width: auto;
    height: ${size(50)};
  }

  @media (max-width: 480px) {
    height: ${size(35)};
  }
`

export const GymComp3Banner = styled.div`
  height: ${size(368)};

  @media (max-width: 480px) {
    height: ${size(226)};
  }
`

export const GymComp3Link = styled.a`
  ${tw`text-white transition-colors border border-white duration-300 flex items-center w-full`}
  max-width: 230px;
  margin: 0 auto;
  margin-top: ${size(43)};
  line-height: 2;
  padding: 0.35rem 2rem;
  font-weight: 500;
  ${fontSize(14)}
`

export const GymComp3Media = styled.div<{ item: number }>`
  ${tw`flex justify-center items-center flex-wrap`}

  ${({ item }) => {
    if (item == 2 || item == 4) {
      return `
          figure {
            width: 50%;
            padding: 0 13px;
            
             @media (max-width: 480px) {
              padding: 0 5px;
             }
          }
        `
    } else if (item == 1 || item == 0) {
      return ''
    } else {
      const newArray = []
      let childArray = []
      for (let i = 1; i <= item; i++) {
        childArray.push(i)
        if (childArray.length % 3 == 0) {
          newArray.push(childArray)
          childArray = []
        }

        if (i == item) {
          newArray.push(childArray)
        }
      }
      for (let o = 1; o <= newArray.length; o++) {
        let child = newArray[o]
        for (let y = 0; y <= child.length; y++) {
          if (child.length % 3 !== 0) {
            return `
            figure {
              &:nth-of-type(${child[0]}){
                width: 50%;
                padding: 0 13px;
                
                @media (max-width: 480px) {
                  padding: 0 8px;
                }
              }
              ${
                child[1]
                  ? `&:nth-of-type(${child[1]}){
                    width: 50%;
                    padding: 0 13px;
                    
                    @media (max-width: 480px) {
                      padding: 0 8px;
                    }
                  }`
                  : ''
              }
            }
          `
          } else {
            return ``
          }
        }
      }
    }
  }}}
`

export const GymCommonMedia = styled.div<{ item: number }>`
  ${tw`flex justify-center items-center flex-wrap`}

  ${({ item }) => {
    if (item == 2 || item == 4) {
      return `
          figure {
            width: 50%;
            padding: 0 13px;
            
             @media (max-width: 480px) {
              padding: 0 5px;
             }
          }
        `
    } else if (item == 1 || item == 0) {
      return ''
    } else {
      const newArray = []
      let childArray = []
      for (let i = 1; i <= item; i++) {
        childArray.push(i)
        if (childArray.length % 3 == 0) {
          newArray.push(childArray)
          childArray = []
        }

        if (i == item) {
          newArray.push(childArray)
        }
      }
      for (let o = 1; o <= newArray.length; o++) {
        let child = newArray[o]
        for (let y = 0; y <= child.length; y++) {
          if (child.length % 3 !== 0) {
            return `
            figure {
              &:nth-of-type(${child[0]}){
                width: 50%;
                padding: 0 13px;
                
                @media (max-width: 480px) {
                  padding: 0 8px;
                }
              }
              ${
                child[1]
                  ? `&:nth-of-type(${child[1]}){
                    width: 50%;
                    padding: 0 13px;
                    
                    @media (max-width: 480px) {
                      padding: 0 8px;
                    }
                  }`
                  : ''
              }
            }
          `
          } else {
            return ``
          }
        }
      }
    }
  }}}
`

export const GymComp3Title = styled.p`
  ${tw`uppercase text-center text-white font-bold relative`}
  ${fontSize(40)}
  padding: 0 ${size(16)};
  padding-bottom: ${size(25)};
  &::before {
    content: '';
    width: 100%;
    max-width: ${size(70)};
    height: ${size(4)};
    ${tw`bg-blue__deep absolute`}
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 480px) {
    ${fontSize(30)}
  }
`

export const GymCommonImage = styled.figure`
  width: 33%;
  margin-bottom: ${size(25)};
  height: ${size(166)};
  cursor: pointer;
  padding: 0 10px;

  @media (max-width: 480px) {
    height: ${size(84)};
    padding: 0 5px;
    margin-bottom: ${size(10)};
  }
`

export const GymCommonBtnWhite = styled.div`
  ${tw`w-full mx-auto flex justify-between items-center`}
  max-width: 300px;

  button {
    width: 47%;
    ${tw`flex justify-center items-center`}
  }
`

export const Icon = styled(SVGIcon)`
  ${tw`hover:text-orange text-white relative`}
  margin-right: ${size(8)}
  top: ${size(1)}
`

export const IconNew = styled(SVGIcon)`
  ${tw`text-white relative`}
  margin-right: ${size(8)}
  top: ${size(1)}
`

export const GymCommonNewLogo = styled.div`
  ${tw`flex items-center w-full flex-wrap`}
  padding: 0 ${size(16)};
  margin-top: ${size(30)};
`

export const GymCommonNewComp = styled.div`
  width: 50%;
  margin-bottom: ${size(47)};
  ${tw`flex justify-center items-center`}

  @media (max-width: 1100px) {
    ${tw`w-full`}
  }

  @media (max-width: 480px) {
    margin-bottom: ${size(26)};
  }
`

export const GymCommonNewLogoImg = styled.div`
  width: ${size(94)};
  height: ${size(90)};

  @media (max-width: 480px) {
    width: ${size(51)};
    width: ${size(50)};
  }
`

export const GymCommonNewLogoInfo = styled.div`
  padding-left: ${size(16)}
  padding-right: ${size(23)}
  width: calc(100% - ${size(150)});
  
  @media (max-width: 480px) {
      width: calc(100% - ${size(51)});
      padding-right: 0;
  }

  p {
    font-size: ${size(16)}
    ${tw`font-bold text-white`}
    
    @media (max-width: 480px) {
      margin-bottom: ${size(5)};
    }
  }
  
  span {
    font-size: ${size(14)}
    ${tw`text-grey__sand`}
    opacity: 0.8;
  }
`

export const TemplateFormContentContainer = styled.div`
  ${tw`w-full xl:w-3/5`}
`
export default AppStyles
